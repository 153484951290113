import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'leaflet';
import { EventService } from 'src/app/core/services/event.service';
import { ApiService } from 'src/app/shared/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    public formBuilder: FormBuilder,
    private eventService: EventService,
    private translateService: TranslateService
    // private modalService: NzModalService,
    // private notification: NzNotificationService
  ) { }
  searchTerm
  listData = []
  pageSize = 10
  pageIndex = 1
  totalCount = 2
  tableName = 'devices'
  role = JSON.parse(localStorage.getItem('currentUser'))['role']
  submitted: boolean
  formData: FormGroup
  listModel = ['MD712', 'MD716', 'MD760', 'Y50', 'Y56', 'F91']
  listVersion = ['Paybox', 'Soundbox']
  serial_number
  codeOTP
  flagOTP = false
  user_id = JSON.parse(localStorage.getItem('currentUser'))['id']
  token = JSON.parse(localStorage.getItem('currentUser'))['user_token']
  workspace_id = localStorage.getItem('workspace');

  // listStatus = ['Trực tuyến' , 'Ngoại tuyến']
  breadCrumbItems

  get form() {
    return this.formData.controls
  }

  ngOnInit() {
    this.breadCrumbItems = [{ label: 'PAGE.HOME' }, { label: 'PAGE.REPORT.IncidentType', active: true }];    
    this.fetchData()
  }

  onChangePage(e) {
    this.pageIndex = e
    this.fetchData()
  }


  fetchData(params = '') {
    params = '&order=updated_at&direction=desc'
    this.eventService.setLoading(true)
    this.apiService.getList(this.tableName +
      `?page=${this.pageIndex}&limit=${this.pageSize}${params}`
      ).subscribe({
      next: (res) => {
        this.listData = res['devices'] ? res['devices'] : [];
        this.totalCount = res['pagination']['totalCount'] ? res['pagination']['totalCount'] : '';
        this.eventService.setLoading(false)
      }
    })
  }

  addLang() {
    const language = this.form.language as FormArray
    let lang = this.makePrice()
    language.push(lang)
  }

  removeLang(i) {
    const language = this.form.language as FormArray
    language.removeAt(i)
  }

  openModal(content: any) {
    this.submitted = false
    this.formData = this.makeForm({ language: [{}] })
    let a = this.modalService.open(content, { centered: true, scrollable: true, size: 'lg' });
  }

  onEdit(data, content) {
    console.log(data)
    this.formData = this.makeForm(data)
    this.modalService.open(content, { centered: true, scrollable: true, size: 'xl' });
  }

  selectedIncident = null
  selectedKeyword = null

  makeFormKeyword(d) {
    let data = d ? d : <any>{}
    return this.formBuilder.group({
      id: [data.id],
      name: [data.name],
      incident_type_id: [data.incident_type_id]
    })
  }

  fetchKeyword() {
    this.dataKeyword = []
    this.eventService.setLoading(true)
    this.apiService.getList(`keyword?type_id=${this.selectedIncident.id}`).subscribe({
      next: res => {
        console.log(res)
        this.dataKeyword = [...res]
        this.eventService.setLoading(false)
      }
    })
  }

  dataKeyword = []

  formEditKeyword: FormGroup
  formNewKeyword: FormGroup
  editKeyword(data, index) {
    this.formEditKeyword = this.makeFormKeyword(data)
    this.selectedKeyword = data
  }

  onSubmitFormKeyword(type) {
    if (type == 1) {
      if (this.formEditKeyword.valid) {
        const value = this.formEditKeyword.value
        this.eventService.setLoading(true)
        this.apiService.editItem('keyword', value.id, value).subscribe({
          next: res => {
            this.selectedKeyword = null
            this.fetchKeyword()
            this.eventService.setLoading(false)

          }
        })
      }
    } else {
      if (this.formNewKeyword.valid) {
        const value = this.formNewKeyword.value
        this.eventService.setLoading(true)
        this.apiService.addItem('keyword', value).subscribe({
          next: res => {
            this.eventService.setLoading(false)
            this.formNewKeyword.reset({ incident_type_id: this.selectedIncident.id })
            this.fetchKeyword()
          }
        })
      }
    }
  }

  onOpenKeywordModal(content, incident) {
    this.selectedIncident = incident
    this.fetchKeyword()
    this.formNewKeyword = this.makeFormKeyword({ incident_type_id: this.selectedIncident.id })
    this.modalService.open(content, { centered: true, scrollable: true, size: 'xl' });
  }

  defaultPrices = [
    { condition: 1, price: 0, unit: '' },
    { condition: 2, price: 0, unit: '' }
  ]

  onDeleteKeyword(id) {
    if (id) {
      this.eventService.setLoading(true)
      this.apiService.deleteItem('keyword', id).subscribe(res => {

        Swal.fire(this.translateService.instant('FORM.Success'), this.translateService.instant('FORM.SuccessMessageDelete'), 'success')
          .then(
            res => {
              this.fetchKeyword();

            }
          )
        this.eventService.setLoading(false)

      }

      )
    }
  }

  onDelete(data) {
    Swal.fire({
      title: this.translateService.instant('FORM.Warning'),
      text: this.translateService.instant('FORM.WarningMessage'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#ff3d60',
      confirmButtonText: this.translateService.instant('FORM.WarningConfirm'),
      cancelButtonText: this.translateService.instant('FORM.Cancel')
    }).then(result => {
      if (result.value) {
        this.apiService.deleteItem(this.tableName, data).subscribe(res =>
          Swal.fire(this.translateService.instant('FORM.Success'), this.translateService.instant('FORM.SuccessMessageDelete'), 'success')
            .then(
              res => {
                this.fetchData();
              }
            )
        )
      }
    });
  }


  
  makeForm(d?) {
    let data = d ? d : <any>{}
    return this.formBuilder.group({
      id: [data.id],
      model : [data.model, [Validators.required]],
      serial_number : [data.serial_number, [Validators.required]],
      version: [data.version, [Validators.required]],
      // status: [data.status, [Validators.required]],
      // currency: [data.currency || 'VND'],
    })
  }

  makePrices(data = []) {
    let arr = new FormArray([]);
    data.forEach(i => {

      arr.push(this.makePrice(i))
    })
    return arr;
  }

  makePrice(d?) {
    let data = d ? d : <any>{}
    return this.formBuilder.group({
      key: [data.key, [Validators.required]],
      // price: [data.price, [Validators.required, Validators.min(0)]],
      value: [data.value, [Validators.required]],
      // id: [data.id]
    })
  }

  uploadedImage

  saveData() {
    this.submitted = true
    if (this.formData.valid) {
      const value = this.formData.value
      value['status'] = value['status'] == true ? 1 : 0
      // value['prices'][0]['condition'] = 1
      // value['prices'][1]['condition'] = 2
      // value['price']= value['prices']
      this.eventService.setLoading(true)
      if (value.id) {
        this.apiService.editItem(this.tableName, value.id, value).subscribe({
          next: res => {
            this.submitted = false
            this.formData.reset()
            this.fetchData()
            this.modalService.dismissAll()
            this.apiService.showToast(this.translateService.instant('FORM.Success'), this.translateService.instant('FORM.SuccessMessage'), 'success')
            this.eventService.setLoading(false)
          },
          error: e => {
            this.apiService.showToast(this.translateService.instant('FORM.Error'), this.translateService.instant('FORM.ErrorMessage'), 'error')
            this.eventService.setLoading(false)
          }
        })
      } else {
        this.apiService.addItem(this.tableName, value).subscribe({
          next: res => {
            this.fetchData()
            this.apiService.showToast(this.translateService.instant('FORM.Success'), this.translateService.instant('FORM.SuccessMessageAdd'), 'success')
            this.eventService.setLoading(false)
            this.modalService.dismissAll()
          },
          error: e => {
            this.apiService.showToast(this.translateService.instant('FORM.Error'), this.translateService.instant('FORM.ErrorMessageAdd'), 'error')
            this.eventService.setLoading(false)
          }
        })
      }

    } else {
      console.log('invalid', this.formData)
      Object.values(this.formData.controls).forEach(control => {
        if (control.status == 'INVALID') {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
  // getOTPDevice() {
    // const modifiedSerialNumber = this.serial_number
    //   ? this.serial_number.toUpperCase()
    //   : '';
    // const data = {
    //   partner_id: this.user_id,
    //   mid: modifiedSerialNumber,
    //   token: this.token
    // }
    // this.apiService.apiPostToPaydee('prod/mbpaybox/otp', data)
    //   .subscribe({
    //     next: (res: any) => {
    //       switch (res.returnCode) {
    //         case "1":
    //           this.flagOTP = true;
    //           break;
    //         case "0001":
    //           this.apiService.showToastMobile(
    //             'error',
    //             this.translateService.instant("PartnerID hoặc Token không hợp lệ"),
    //           );
    //           return false;
    //         case "0002":
    //           this.apiService.showToastMobile(
    //             this.translateService.instant('warning'),
    //             this.translateService.instant("Vui lòng nhập mã thiết bị"),
    //           );
    //           return false;
    //         case "0003":
    //           this.apiService.showToastMobile(
    //             'error',
    //             this.translateService.instant("Thiết bị không tồn tại hoặc đã được gán cho cửa hàng khác."),
    //           );
    //           return false;

    //         default:
    //           this.apiService.showToastMobile(
    //             'error',
    //             this.translateService.instant("Tạo thất bại"),
    //           );
    //           return false;
    //       }
    //     },
    //     error: e => {
    //       this.apiService.showToastMobile('error', this.translateService.instant('FORM.ErrorMessageAdd'))
    //       this.eventService.setLoading(false)
    //     }
    //   })
  // }
  getOTPDevice() {
    const modifiedSerialNumber = this.serial_number
      ? this.serial_number.toUpperCase()
      : '';
    const data = {
      partner_id: this.user_id,
      mid: modifiedSerialNumber,
      token: this.token
    }
    this.apiService.postItem('devices', {serial_number:modifiedSerialNumber})
      .subscribe({
        next: (res: any) => {
          this.eventService.setLoading(false)
          this.fetchData()
          this.modalService.dismissAll()
        },
        error: e => {
          if(e){
            this.apiService.showToastMobile('error', this.translateService.instant(e))
            this.eventService.setLoading(false)
          } else {
            this.apiService.showToastMobile('error', this.translateService.instant('FORM.ErrorMessageAdd'))
            this.eventService.setLoading(false)
          }
        }
      })
  }
  addDevice() {
    const modifiedSerialNumber = this.serial_number
      ? this.serial_number.toUpperCase()
      : '';

    this.eventService.setLoading(true)
    const data = {
      serialNumber: modifiedSerialNumber,
      otpDevice: this.codeOTP
    };
    this.apiService.postItem('verify-otp-device', data).subscribe({
      next: (res: any) => {
        const dataDevice = {
          serial_number: modifiedSerialNumber,
        };
        this.apiService.addItem('devices', dataDevice).subscribe({
          next: res => {
            this.apiService.showToastMobile(
              this.translateService.instant('success'),
              this.translateService.instant('FORM.SuccessMessageAdd'),
            );
            const data = {
              deviceId: res['metadata']?.deviceId,
            }
            const id = this.workspace_id;
            this.apiService.postItem('workspaces/link-device/' + id, data).subscribe({
              next: res => {
                this.eventService.setLoading(false);
              },
              error: e => {
               if(e){
                this.apiService.showToastMobile('error', this.translateService.instant(e));
                this.eventService.setLoading(false);
               }else{
                this.apiService.showToastMobile('error', this.translateService.instant('Liên kết thất bại'));
                this.eventService.setLoading(false);
               }
              }
            });
          },
          error: e => {
            if (e == 'error.message.serialNumberExisted') {
              this.apiService.showToastMobile(
                'error',
                this.translateService.instant('Thiết bị đã được liên kết'),
              );
            } else if(e){
              this.apiService.showToastMobile(
                'error',
                this.translateService.instant(e),
              );
            }
            else{
              this.apiService.showToastMobile(
                'error',
                this.translateService.instant('FORM.ErrorMessageAdd'),
              );
            }
          }
        });
        this.eventService.setLoading(false);
      },
      error: e => {
        if (e) {
          this.apiService.showToastMobile(
            'error',
            this.translateService.instant(e),
          );
          this.eventService.setLoading(false);
        }
        else {
          this.apiService.showToastMobile(
            'error',
            this.translateService.instant('FORM.ErrorMessageAdd'),
          );
          this.eventService.setLoading(false);
        }

      }
    });
  }

  goToPreviousPage() {
    if (this.pageIndex > 1) {
        this.pageIndex--;
        this.fetchData();
    }
}

goToNextPage() {
    if (this.pageIndex < this.totalCount) {
        this.pageIndex++;
        this.fetchData();
    }
}
}
