import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { ServicesComponent } from './services/services.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { OrdersComponent } from './listtransactions/orders.component';
import { LocationComponent } from './location/location.component';
import { RolesComponent } from './listdevices/roles.component';
import { UsersComponent } from './users/users.component';
import { IncidentComponent } from './incident/incident.component';
import { CustomersComponent } from './customers/customers.component';
import { DetailsComponent } from './detailsCutstomers/details.component';
import { ListTransactionsComponent } from './list-transactions/list-transactions.component';
import { DetailsTransactionsComponent } from './details-transactions/details-transactions.component';
import { ListQrpayCodeComponent } from './list-qrpay-code/list-qrpay-code.component';
import { DetailQrpayCodeComponent } from './detail-qrpay-code/detail-qrpay-code.component';
import { TokensManageComponent } from './tokens-manage/tokens-manage.component';
import { CreateOrderComponent } from './create-order/create-order.component';
import { LoginComponent } from '../account/auth/login/login.component';
import { DetailCreateOrderComponent } from './detail-create-order/detail-create-order.component';
import { AuthRoleGuard } from '../core/guards/authRole.guard';
// import { ListWorkspaceComponent } from './list-workspace/list-workspace.component';
import { AccountComponent } from './account/account.component';
import { ListMemberComponent } from './list-member/list-member.component';
import { ListWorkspaceComponent } from './list-workspace/list-workspace.component';
import { DetailWorkspaceComponent } from './detail-workspace/detail-workspace.component';
import { SearchTransactionsComponent } from './search-transactions/search-transactions.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { DeviceStockComponent } from './device-stock/device-stock.component';


const routes: Routes = [
    // { 
    //     path: '',
    //     redirectTo:'login',
    //     pathMatch: 'full'
    // },
    // { path: '', component: LoginComponent},
    {
        path: '', component: DashboardComponent, canActivate: [AuthRoleGuard],data: {
            accessRole: ['admin' , 'user']
        } 
    },
    {
        path: 'edit-profile', component: EditProfileComponent, canActivate: [AuthRoleGuard], data: {
            accessRole: ['user']
        }
    },
    //token
    { path: 'tokens', component: TokensManageComponent, canActivate: [AuthRoleGuard], },
    {
        path: 'person', component: AccountComponent
        , canActivate: [AuthRoleGuard],data: {
            accessRole: ['user']
        } 
    },

    //devices
    { path: 'deviceslist', component: RolesComponent, canActivate: [AuthRoleGuard], data: {
            accessRole: ['admin','user']
        } 
    },
    {
        path: 'users', component: UsersComponent
        , canActivate: [AuthRoleGuard], data: {
            accessRole: ['admin']
        }
    },
    // { path: 'booking', component: BookingComponent },
    // { path: 'location', component: LocationComponent, canActivate: [AuthRoleGuard], data: {
    //         accessRole: ['admin', 'user']
    //     } },
    // { path: 'incident', component: IncidentComponent, canActivate: [AuthRoleGuard], data: {
    //         accessRole: ['admin', 'user']
    //     } },
    { path: 'users/details/:id', component: DetailsComponent, canActivate: [AuthRoleGuard], data: {
        accessRole: ['admin', 'user']
    } },

    //transactions  
    { path: 'transactions/details-transactions/:id', component: DetailsTransactionsComponent, canActivate: [AuthRoleGuard], data: {
            accessRole: ['admin','user']
        } },
    // { path: '404', component: PagenotfoundComponent, canActivate: [AuthRoleGuard], data: {
    //     accessRole: ['admin','user']
    // } },
    { path: 'transactions', component: ListTransactionsComponent, canActivate: [AuthRoleGuard], data: {
        accessRole: ['admin','user']
    } },
    { path: 'device-stock', component: DeviceStockComponent, canActivate: [AuthRoleGuard], data: {
        accessRole: ['admin']
    } },
    { path: 'search-transactions', component: SearchTransactionsComponent, canActivate: [AuthRoleGuard], data: {
        accessRole: ['user']
    } },
    //qr-pay
    { path: 'qrpay-code', component: ListQrpayCodeComponent, canActivate: [AuthRoleGuard], data: {
            accessRole: ['admin','user']
        } },
    { path: 'qrpay-code/:id', component: DetailQrpayCodeComponent, canActivate: [AuthRoleGuard], data: {
            accessRole: ['admin','user']
        } },
    { path: 'detail-qrpay-code', component: DetailQrpayCodeComponent, canActivate: [AuthRoleGuard], data: {
            accessRole: ['user']
    } },

    //create-order
    { path: 'create-order', component: CreateOrderComponent, canActivate: [AuthRoleGuard], data: {
            accessRole: ['user']
        } },
    { path: 'detail-create-order', component: DetailCreateOrderComponent, canActivate: [AuthRoleGuard], data: {
            accessRole: ['user']
        } },

    //member
    { path: 'member', component: ListMemberComponent, canActivate: [AuthRoleGuard], data: {
        accessRole: ['admin','user']
    } },

    //workspace
    { path: 'workspaces', component: ListWorkspaceComponent, canActivate: [AuthRoleGuard], data: {
        accessRole: ['admin','user']
    } },
    { path: 'workspaces/detail-workspace/:id', component: DetailWorkspaceComponent, canActivate: [AuthRoleGuard], data: {
        accessRole: ['admin','user']
    } },
    
    // { path: 'kanban-board', component: KanbanComponent },
    // { path: 'settings', component: SettingsComponent },
    // { path: 'ecommerce', loadChildren: () => import('./ecommerce/ecommerce.module').then(m => m.EcommerceModule) },
    // { path: 'email', loadChildren: () => import('./email/email.module').then(m => m.EmailModule) },
    // { path: 'pages', loadChildren: () => import('./utility/utility.module').then(m => m.UtilityModule) },
    // { path: 'ui', loadChildren: () => import('./ui/ui.module').then(m => m.UIModule) },
    // { path: 'icons', loadChildren: () => import('./icons/icons.module').then(m => m.IconsModule) },
    // { path: 'charts', loadChildren: () => import('./chart/chart.module').then(m => m.ChartModule) },
    // { path: 'form', loadChildren: () => import('./form/form.module').then(m => m.FormModule) },
    // { path: 'tables', loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule) },
    // { path: 'maps', loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule) },
    // { path: 'report', loadChildren: () => import('./report/report.module').then(m => m.ReportModule) },

];

@NgModule({
    imports: [RouterModule.forChild(routes),
        // QRCodeModule,
    ],
    exports: [RouterModule]
    //
})
export class PagesRoutingModule { }
